












import { Inject } from 'inversify-props';
import { DatePicker } from 'element-ui';
import { $enum } from 'ts-enum-util';
import { Component, Mixins } from 'vue-property-decorator';

import DownloadReportForm, { DownloadReportControlMixin, FilterParam } from '@/modules/common/components/download-report-form.vue';
import LosDocumentFilter from '@/modules/document-filters/components/los-document-filter.vue';
import CurrencySwitcher from '@/modules/common/components/currency-switcher.vue';
import UserSettingsService, { UserSettingsS } from '@/modules/user/user-settings.service';
import RatesFilterItemsMixin from '../mixins/rates-filters.mixin';
import { RatesDownloadExcelForm } from '../rates-api.service';
import PRICE_SHOWN from '../constants/price-shown.constant';
import RatesAnalysisFiltersService, { RatesAnalysisFiltersServiceS } from '../rates-analysis-filters.service';

@Component({
    components: {
        DownloadReportForm,
    },
})
export default class RatesDownloadExcelPopup extends Mixins(RatesFilterItemsMixin, DownloadReportControlMixin) {
    @Inject(UserSettingsS)
    private userSettingsService!: UserSettingsService;

    @Inject(RatesAnalysisFiltersServiceS)
    private ratesAnalysisFiltersService!: RatesAnalysisFiltersService;

    public form: RatesDownloadExcelForm = {} as RatesDownloadExcelForm;
    private isLoading = false;

    public get attrs() {
        const { filters, customColumns } = this;
        const { properties, buttons } = this;
        const { isLoading } = this;

        return {
            filters,
            customColumns,
            properties,
            buttons,
            isLoading,
            dataType: this.isAnalysisMode ? 'Analysis' : 'Rates',
        };
    }

    protected get isAnalysisMode() {
        return this.$route.name!.includes('.analysis');
    }

    private get isRangeValid() {
        const { monthrange } = this.form;

        const maxRangeInMonths = 12;
        const start = new Date(monthrange[0]).getMonth();
        const startYear = new Date(monthrange[0]).getFullYear();
        let end = new Date(monthrange[1]).getMonth();
        const endYear = new Date(monthrange[1]).getFullYear();

        if (endYear > startYear) {
            end += 12;
        }

        return end - start < maxRangeInMonths;
    }

    private get isDownloadDisabled() {
        const { pos, los, priceType } = this.form;
        const { provider, roomTypeId } = this.form;
        const { mealTypeId, monthrange } = this.form;

        if (!monthrange || !monthrange.length) return true;
        if (!this.isRangeValid) return true;

        if (!pos) return true;
        if (!los) return true;
        if (!provider) return true;

        if (this.isAnalysisMode) {
            if (!roomTypeId) return true;
            if (!mealTypeId) return true;
            if (!priceType) return true;
        } else {
            if (!priceType.length) return true;
            if (!roomTypeId.length) return true;
            if (!mealTypeId.length) return true;
        }

        return false;
    }

    private get buttons() {
        const buttons = [
            {
                label: 'Download now',
                onClick: () => this.downloadExcel(),
                isDisabled: this.isDownloadDisabled,
            },
        ];

        if (!this.isAnalysisMode) {
            buttons.push(
                {
                    label: 'Send to email',
                    onClick: () => this.downloadExcel(true, false),
                    isDisabled: this.isDownloadDisabled,
                },
                {
                    label: 'On Demand and Send',
                    onClick: () => this.downloadExcel(false, true),
                    isDisabled: this.isDownloadDisabled,
                },
            );
        }

        return buttons;
    }

    private get properties() {
        return [
            {
                label: 'Date range',
                key: 'monthrange',
                component: DatePicker,
                default: [],
                props: {
                    type: 'daterange',
                    rangeSeparator: '-',
                    startPlaceholder: 'Start Month',
                    endPlaceholder: 'End month',
                    format: 'MMM dd, yyyy',
                    valueFormat: 'yyyy-MM-dd',
                    clearable: false,
                },
            },
            {
                label: 'Currency',
                key: 'displayCurrency',
                component: CurrencySwitcher,
                default: this.userSettingsService.displayCurrency,
            },
        ];
    }

    private get customColumns() {
        if (this.isAnalysisMode) return [];

        return [
            {
                key: 'market_demand',
                label: 'Market Demand',
                default: true,
            },
            {
                key: 'occupancy',
                label: 'Occupancy',
                default: true,
            },
            {
                key: 'rank',
                label: 'Rank',
                default: true,
            },
            {
                key: 'diff_delta',
                label: 'Diff #',
                default: true,
            },
            {
                key: 'diff_precent',
                label: 'Diff %',
                default: true,
            },
            {
                key: 'median',
                label: 'Median',
                default: true,
            },
            {
                key: 'mealType',
                label: 'Meal Type',
                default: true,
            },
            {
                key: 'roomType',
                label: 'Room Type',
                default: true,
            },
            {
                key: 'roomName',
                label: 'Room Name',
                default: true,
            },
        ];
    }

    private get filters() {
        const { provider } = this.form;

        const isShortFilter = ['google', 'all'].includes(provider!);
        const isNumberOfGuestDisabled = true
            && this.providersService.isDisabledProvider(provider!)
            && provider !== 'all';

        const priceShownItems = $enum(PRICE_SHOWN)
            .getValues()
            .filter(e => e !== 'Calculated')
            .map(value => ({ value, name: value }));

        const { priceShown } = this.ratesFiltersService.settings;
        const defaultPriceType = this.priceTypeItems
            .find(p => p.value === this.priceType) || this.priceTypeItems[0];

        const mealTypeItems = this.mealTypesService.mealTypes
            .filter(i => this.isAnalysisMode || i.id !== -1)
            .map(item => ({
                name: item.displayName,
                value: item.name,
            }));

        const roomTypeItems = this.roomTypeItems.filter(i => i.value !== -1);
        const providerItems = this.providerItems.filter(i => i.value !== 'all');

        const defaultProvider = this.provider === 'all'
            ? providerItems[0] && providerItems[0].value
            : this.provider;

        const filters = [
            {
                label: 'Comp Set',
                key: 'compsetId',
                options: this.compsetTypeItems,
                default: this.compsetTypeItems[0]
                    ? this.compsetTypeItems[0].value
                    : null,
            },
            {
                label: 'Source',
                key: 'provider',
                options: providerItems,
                default: defaultProvider,
            },
            {
                label: 'POS',
                key: 'pos',
                options: this.posItems,
                default: this.posItems[0]
                    ? this.pos || this.posItems[0].value
                    : 'US',
            },
            {
                label: 'Price',
                key: 'priceType',
                options: this.priceTypeItems,
                default: this.isAnalysisMode
                    ? defaultPriceType.value
                    : [defaultPriceType],
                disabled: isShortFilter,
                multiselect: !this.isAnalysisMode,
            },
            {
                label: 'LOS',
                key: 'los',
                default: this.los || 1,
                component: LosDocumentFilter,
                props: {
                    customization: false,
                },
                disabled: provider === 'all',
            },
            {
                label: 'Number of guests',
                key: 'numberOfGuests',
                options: this.numberOfGuestsItems,
                default: this.numberOfGuests || this.numberOfGuestsItems[0].value,
                disabled: isShortFilter || isNumberOfGuestDisabled,
            },
            {
                label: 'Room Type',
                key: 'roomTypeId',
                options: this.isAnalysisMode
                    ? this.roomTypeItems
                    : roomTypeItems,
                default: !this.isAnalysisMode
                    ? [...roomTypeItems]
                    : this.roomTypeItems[0] && this.roomTypeItems[0].value,
                disabled: isShortFilter,
                multiselect: !this.isAnalysisMode,
            },
            {
                label: 'Meal Type',
                key: 'mealTypeId',
                options: mealTypeItems,
                default: !this.isAnalysisMode
                    ? [...mealTypeItems]
                    : mealTypeItems[0] && mealTypeItems[0].value,
                disabled: isShortFilter,
                multiselect: !this.isAnalysisMode,
            },
            {
                label: 'Price shown',
                key: 'priceShown',
                default: priceShown || PRICE_SHOWN.SHOWN,
                options: priceShownItems,
            },
        ];

        if (this.isAnalysisMode) {
            this.updateAnalysisFilters(filters as FilterParam[]);
        }

        return filters;
    }

    private updateAnalysisFilters(filters: FilterParam[]) {
        const { comparsionTypeItems } = this;
        const defaultCompareFilter = this.ratesAnalysisFiltersService.comparisonKey || 'diffDays';
        const compareTo = (this.form.compareTo! || defaultCompareFilter) as keyof RatesDownloadExcelForm;

        const mainValue = this.form[compareTo];

        const compareItems = this.ratesAnalysisFiltersService
            .filterItems[compareTo](true)
            .filter(i => i.value !== mainValue);

        this.getForm(f => {
            /* eslint-disable */
            f.formWatcher.compareValue = compareItems[0].value;
            /* eslint-enable */
        });

        filters.push(
            { divider: true } as any,
            { divider: true } as any,
            { divider: true } as any,
            { divider: true } as any,
            { divider: true } as any,
            { divider: true } as any,
            {
                label: 'Compare to',
                key: 'compareTo',
                options: comparsionTypeItems,
                default: defaultCompareFilter,
            },
            {
                label: 'Compare Value',
                key: 'compareValue',
                options: compareItems,
                default: compareItems[0].value,
            },
        );
    }

    private get comparsionTypeItems() {
        const compareFilter = this.ratesAnalysisFiltersService.filterList;
        const { provider, compareTo } = this.form;

        if (provider === 'cheapest') {
            if (compareTo === 'diffDays') {
                this.getForm(f => {
                    // eslint-disable-next-line
                    f.formWatcher.compareTo = 'provider';
                });
            }

            return compareFilter
                .filter(item => item.value !== 'diffDays');
        }

        return compareFilter;
    }

    private getPreparedForm() {
        if (this.isAnalysisMode) {
            // NOTE: Since for compare mode we use <CustomSelect /> component
            //       that returns values directly instead of Item[] like <CustomMultiSelect />
            //       so we must convert back these values to Item[] type.
            const { roomTypeId, mealTypeId, priceType } = this.form as unknown as {
                roomTypeId: number;
                mealTypeId: string;
                priceType: string;
            };

            const roomTypeName = this.roomTypeItems.find(i => i.value === roomTypeId)!.name;

            const roomTypeParams = roomTypeId === -1
                ? this.roomTypeItems.filter(i => i.value !== -1)
                : [{ name: roomTypeName, value: roomTypeId }];
            const mealTypeParams = mealTypeId === 'any'
                ? this.mealTypesService.mealTypes
                    .filter(i => i.id !== -1)
                    .map(i => ({ value: i.name, name: i.displayName }))
                : [{ name: mealTypeId, value: mealTypeId }];

            return {
                ...this.form,
                roomTypeId: roomTypeParams,
                mealTypeId: mealTypeParams,
                priceType: [{ name: priceType, value: priceType }],
            } as RatesDownloadExcelForm;
        }

        return this.form;
    }

    private async downloadExcel(toEmail = false, onDemand = false) {
        try {
            this.isLoading = true;
            const form = this.getPreparedForm();
            await this.ratesService.getExcel(form, toEmail, onDemand);

            if (toEmail) {
                this.triggerFormMessage('Report sent');
            } else {
                this.closeForm();
            }
        } catch (_) {
            this.triggerFormError('There is no data. Try change the filters.');
        } finally {
            this.isLoading = false;
        }
    }
}
